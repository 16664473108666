import React from "react"
import Message from "elements/Message"
import Section from "elements/Section"
import ReceiptsSummaryRow from "./ReceiptsSummary/ReceiptsSummaryRow"
import EditDetailsButton from "elements/EditDetailsButton"
import classNames from "classnames"

import styles from "../Summary/utils/summary.module.scss"

const ReceiptsSummary = ({ route, receipts }) => {
  return (
    <Section
      title={`Receipts Uploaded`}
      addOns={{
        right: <EditDetailsButton route={route} />,
      }}
    >
      {receipts?.length > 0 ? (
        <div className="table-container">
          <table className={classNames(styles["table"], "table is-fullwidth")}>
            <thead>
              <tr>
                <th>Receipt Number</th>
                <th>Receipt Date</th>
                <th>Total Encoded</th>
                <th>Receipt Uploaded</th>
              </tr>
            </thead>
            <tbody>
              {receipts?.map((receipt) => (
                <ReceiptsSummaryRow receipt={receipt} />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <Message color="warning">
          <p className="has-text-black ">You have not uploaded any receipts.</p>
        </Message>
      )}
    </Section>
  )
}

export default ReceiptsSummary
