import { createZendeskTemplate } from "../utils/createZendeskTemplate"
import { parseInternalNote } from "../../../../../services/parseInternalNote"

import {
  generateUploadFiles,
  zendeskApi,
  zendeskUploadFiles,
} from "../../../../../services/zendeskService"

import { sendMessage } from "../../../../../services/telerivetApi"
import { flexmedTextMessageTemplate } from "../../../../../services/utils/smsTemplates"

const TEST_ENV = process.env.GATSBY_ENV === "production" ? "" : "[TEST] "

const createZendeskRequestTemplate = ({
  flexmedReimburseState,
  state,
  formFields,
}) => {
  const htmlBanner = state?.banner
  const filteredBanner = htmlBanner?.replace(/<[^>]+>/g, "")
  let tags = ["reimburse", "fm", "foundever", "sitel"]
  const subject = `[FM Reimburse] Request from ${flexmedReimburseState?.email}`
  if (process.env.GATSBY_ENV !== "production") tags.push("test")

  let newFlexmedReimburseState = { ...flexmedReimburseState }

  delete newFlexmedReimburseState.documents
  delete newFlexmedReimburseState.receipts

  return {
    request: {
      type: "request",
      subject: `${TEST_ENV}${subject}`,
      requester: {
        name:
          `${flexmedReimburseState?.firstName} ${flexmedReimburseState?.lastName}` ||
          "",
        email: flexmedReimburseState?.email || "it@medgrocer.com",
      },
      comment: {
        html_body: createZendeskTemplate({
          state: flexmedReimburseState,
          formFields: formFields,
        }),
      },
      custom_fields: [
        {
          id: process.env.GATSBY_PBM_ZENDESK_STATE_CUSTOM_FIELD_ID,
          value: JSON.stringify({ ...newFlexmedReimburseState }),
        },
        {
          id: process.env.GATSBY_PBM_ZENDESK_HOLIDAY_BANNER_CUSTOM_FIELD_ID,
          value: filteredBanner,
        },
      ],
      tags,
    },
  }
}

export const handleSubmitReimburseRequest = async ({
  flexmedReimburseState,
  state,
  formFields,
  callback,
  errorCallback,
  files,
}) => {
  const { mobileNumber } = flexmedReimburseState
  try {
    let pbmZendeskConfig = {
      email: process.env.GATSBY_PBM_ZENDESK_EMAIL,
      apiKey: process.env.GATSBY_PBM_ZENDESK_API_KEY,
      apiUrl: process.env.GATSBY_PBM_ZENDESK_SUBDOMAIN,
    }
    let requestTemplate = createZendeskRequestTemplate({
      flexmedReimburseState,
      state,
      formFields,
    })

    const generatedFileToken = files

    const comment = {
      ...requestTemplate.request.comment,
      uploads: generatedFileToken,
    }
    requestTemplate = { request: { ...requestTemplate.request, comment } }

    let response = await zendeskApi({ ...pbmZendeskConfig }).post(
      "/requests.json",
      requestTemplate
    )

    let internalNote = parseInternalNote({ state: flexmedReimburseState })

    try {
      await zendeskApi({ ...pbmZendeskConfig }).put(
        `/tickets/${response.data.request.id}.json`,
        {
          ticket: {
            comment: {
              body: `For IT use:\n\n${JSON.stringify(internalNote)}`,
              public: false,
            },
          },
        }
      )
    } catch (error) {}

    // await sendMessage({
    //   recipient: mobileNumber,
    //   message: flexmedTextMessageTemplate({
    //     zendeskID: response.data.request.id,
    //   }),
    // })

    if (callback) callback()
  } catch (error) {
    if (errorCallback) errorCallback(error)
  }
}
