import { formatDate } from "../../../../../services/date"

export const createZendeskBodyFields = ({ module, state }) => {
  let template = ""
  module.fields.map((field) => {
    if (!!state[field.name]) {
      let key = field.name
      switch (field.type) {
        case "date":
          if (state[key]?.month?.value) {
            template =
              template +
              `${field.zendeskKey}: ` +
              formatDate({ date: state[key], format: "ll" }) +
              "<br />"
          }
          break
        case "select":
          switch (true) {
            case field.zendeskKey.includes("Site Name"):
              let addressType = "Office"
              template =
                template +
                `${field.zendeskKey}: ${state[key]?.value}<br />Address Type: ${addressType}<br />`
              break

            default:
              template =
                template + `${field.zendeskKey}: ${state[key]?.value}<br / >`
              break
          }
          break
        case "checkbox":
          if (state[key]) {
            if (field.zendeskKey && state[key].length)
              template = template + `${field.zendeskKey}: ${state[key]}<br />`
          }
          break
        case "number":
          template = template + `${field.zendeskKey}: ${state[key]}<br />`
          break
        case "radio":
        case "text":
          switch (true) {
            case field.zendeskKey.includes("Bank Account Number"):
              template = template + `${field.zendeskKey}: ${state[key]}<br />`
              break
            case field.zendeskKey.includes("Bank Account Name"):
              template = template + `${field.zendeskKey}: ${state[key]}<br />`
              break
            default:
              template = template + `${field.zendeskKey}: ${state[key]}<br />`
              break
          }
          break
        case "textarea":
        default:
          if (state[key] && field.zendeskKey)
            template = template + `${field.zendeskKey}: ${state[key]}<br />`
          break
      }
    }
    return null
  })
  return template
}
