import React from "react"
import FileThumbnail from "../UploadedDocumentsSummary/FileThumbnail"

const ReceiptsSummaryRow = ({ receipt }) => {
  const { receiptNumber, dateOfPurchase, items } = receipt
  const dateOfPurchaseString = `${dateOfPurchase?.month?.value} ${dateOfPurchase?.date?.value} ${dateOfPurchase?.year?.value}`
  const documentOldName = receipt?.uploads?.[0]?.oldname?.split(".")
  const filetype = documentOldName[documentOldName.length - 1]
  const isPdf = filetype === "pdf"
  const totalEncodedPriceString = items
    ?.reduce(
      (previousValue, currentValue) =>
        parseFloat(previousValue) + parseFloat(currentValue?.itemPrice),
      0
    )
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

  return (
    <tr>
      <td>{receiptNumber}</td>
      <td>{dateOfPurchaseString}</td>
      <td>Php {totalEncodedPriceString}</td>
      <td>
        <FileThumbnail file={receipt?.uploads[0]} isPdf={isPdf} />
      </td>
    </tr>
  )
}

export default ReceiptsSummaryRow
