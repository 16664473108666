import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import FileFigure from "./FileFigure"
import FilePreview from "./FilePreview"
import styles from "../utils/summary.module.scss"

import { AppContext } from "../../../../../context/AppContext"
import { isPdf } from "../../../../Upload/services/uploadDocuments"

const FileThumbnail = ({ file }) => {
  const data = useStaticQuery(graphql`
    {
      pdfThumbnail: file(relativePath: { eq: "pdf.png" }) {
        childImageSharp {
          fixed(width: 64) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const pdfThumbnail = data.pdfThumbnail.childImageSharp.fixed

  const { dispatch } = useContext(AppContext)
  let oldFileNameArray = file.oldname.split(".")
  let fileType = oldFileNameArray[oldFileNameArray.length - 1] || "file"

  const handleFileClick = () =>
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        content: <FilePreview file={file} isPdf={fileType === "pdf"} />,
      },
    })

  return (
    <div
      className={classNames("media")}
      onClick={handleFileClick}
      onKeyDown={(event) => {
        if (event.key === "Enter") handleFileClick()
      }}
      role="button" // Add a role attribute
      tabIndex={0} // Add a tabIndex for keyboard accessibility
    >
      <FileFigure isPdf={isPdf(file.path)} filename={file?.filename}>
        {file.oldname?.includes("pdf") ? (
          <Img fixed={pdfThumbnail} alt="pdfThumbnail" />
        ) : (
          <img
            className={`image ${styles["column__figureMaxSize"]}`}
            src={file.path}
            alt="attachment"
          />
        )}
      </FileFigure>
    </div>
  )
}

export default FileThumbnail
