import React, { useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "layout"
import Container from "layout/Container"
import ViberModal from "elements/ViberModal"
import ButtonGroup from "../../../Elements/ButtonGroup"
import ViberSlidingModal from "../../../Elements/ViberSlidingModal"

import PersonalInformationSummary from "./PersonalInformationSummary"
import ReceiptsSummary from "./ReceiptsSummary"
import UploadDocumentsSummary from "./UploadDocumentsSummary"
import ConsentAndAuthorization from "./ConsentAndAuthorization"

import { FlexmedReimburseContext } from "../../FlexmedContext/FlexmedReimburseContext"
import { handleSubmitReimburseRequest } from "./services/handleSubmitReimburseRequest"
import { useAirtableFMRFormFields } from "../../FlexmedContext/hooks/useAirtableFMRFormFields"
import { flattenNode } from "../../../../services/arrays"
import { AppContext } from "../../../../context/AppContext"

const Summary = ({ pageContext }) => {
  const { state, dispatch } = useContext(AppContext)
  const { flexmedReimburseState, flexmedReimburseDispatch } = useContext(
    FlexmedReimburseContext
  )

  const [loading, setLoading] = useState(false)

  let formFields = useAirtableFMRFormFields()
  formFields = flattenNode(formFields?.allAirtableallFlexmedReimburseFormFields)

  const handleSuccessCallback = () => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        isCard: true,
        heading: " ",
        headerClass: "has-background-viber",
        hideCloseButton: true,
        hideOnMobile: true,
        content: (
          <ViberModal
            callback={() => {
              navigate(pageContext.nextPath, {
                state: {
                  email: flexmedReimburseState?.email,
                },
              })
              setLoading(false)
            }}
            dispatch={dispatch}
          />
        ),
      },
    })
    dispatch({
      type: "SHOW_SLIDING_MODAL",
      payload: {
        isCard: true,
        content: (
          <ViberSlidingModal
            callback={() => {
              navigate(pageContext.nextPath, {
                state: {
                  email: flexmedReimburseState?.email,
                },
              })
              setLoading(false)
            }}
            dispatch={dispatch}
          />
        ),
      },
    })
  }

  const handleErrorCallback = () => {
    setLoading(false)
  }

  const getAllUploadedFiles = () => {
    let uploads = []
    let prescriptions = flexmedReimburseState?.documents.map(
      (document) => document?.token
    )
    let receipts = flexmedReimburseState?.receipts
    // get all prescriptions uploaded and push into uploads
    uploads = [...uploads, ...prescriptions]
    // get all receipts uploaded and push into uploads
    receipts.forEach((receipt) => {
      let receiptUploads = receipt?.uploads?.map((upload) => upload?.token)
      uploads = [...uploads, ...receiptUploads]
    })

    return uploads
  }

  const handleOnSubmit = async () => {
    let uploads = getAllUploadedFiles()
    setLoading(true)
    await handleSubmitReimburseRequest({
      callback: handleSuccessCallback,
      errorCallback: handleErrorCallback,
      flexmedReimburseState,
      state,
      formFields,
      files: uploads,
    })
  }

  // let uploads = []
  // let prescriptions = flexmedReimburseState?.documents.map(
  //   (document) => document.token
  // )
  // let receipts = flexmedReimburseState?.receipts
  // // get all prescriptions uploaded and push into uploads
  // uploads = [...uploads, ...prescriptions]
  // // get all receipts uploaded and push into uploads
  // receipts.forEach((receipt) => {
  //   let receiptUploads = receipt?.uploads?.map((upload) => upload.token)
  //   uploads = [...uploads, ...receiptUploads]
  // })

  useEffect(() => {
    flexmedReimburseDispatch({
      type: "GET_CONTEXT_FROM_SESSION",
    })
    // eslint-disable-next-line
  }, [])

  return (
    <Layout {...pageContext?.module} pageContext={pageContext}>
      <Container isCentered desktop={6}>
        <PersonalInformationSummary
          route={`/${pageContext?.module?.name}/reimburse/employee-information`}
          data={flexmedReimburseState}
        />
        <ReceiptsSummary
          route={`/${pageContext?.module?.name}/reimburse/encode`}
          receipts={flexmedReimburseState?.receipts}
        />
        <UploadDocumentsSummary
          route={`/${pageContext?.module?.name}/reimburse/upload`}
          documents={flexmedReimburseState?.documents}
        />
        <ConsentAndAuthorization
          handleOnSubmit={handleOnSubmit}
          flow="flexmed_reimburse"
        >
          <section className="message is-warning p-1 my-2 has-text-centered">
            Eligibility is not yet final until verified by our pharmacists via
            email.
          </section>
          <ButtonGroup
            back={{
              link: pageContext.backPath,
            }}
            next={{
              label: `Submit`,
              link: pageContext.nextPath,
              loading: loading,
              type: "submit",
              disabled:
                flexmedReimburseState?.documents?.length === 0 ||
                flexmedReimburseState?.agreeToMechanics?.length === 0 ||
                flexmedReimburseState?.consentAndAuthorization?.length < 3,
            }}
          />
        </ConsentAndAuthorization>
      </Container>
    </Layout>
  )
}

export default Summary
