import { createZendeskBodyFields } from "./createZendeskBodyFields"
import { formatDate } from "./formatDate"

export const createZendeskTemplate = ({ state, formFields }) => {
  let moduleNames = [
    ...new Set(
      formFields
        .filter((field) => field.zendeskOrder)
        .map((field) => field.module)
    ),
  ]
  let zendeskOrder = [
    ...new Set(
      formFields
        .filter((field) => field.zendeskOrder)
        .map((field) => field.zendeskOrder)
    ),
  ]

  moduleNames = moduleNames.map((item) => ({ module: item }))

  moduleNames.forEach((moduleName, index) => {
    let filteredFormFields = formFields.filter(
      (field) =>
        field.module === moduleName.module && field.showOnZendesk === true
    )

    filteredFormFields = filteredFormFields.map((field) => {
      return {
        name: field.name,
        type: field.type,
        zendeskKey: field.zendeskKey,
      }
    })

    moduleNames[index].fields = filteredFormFields
    moduleNames[index].fields.reverse()
  })

  moduleNames.forEach(
    (moduleName, index) => (moduleName.zendeskOrder = zendeskOrder[index])
  )

  moduleNames = moduleNames.sort((a, b) => a.zendeskOrder - b.zendeskOrder)

  let template = ""

  let header = `<strong style="color:#0d6d6e;"> FLEXMED REIMBURSE </strong><br/><br/>`
  template = template + header
  moduleNames.map((module) => {
    template = template + `<strong><u>${module.module}</u></strong><br/><br/>`
    template =
      template +
      createZendeskBodyFields({
        module: module,
        state: state,
      })
    template = template + "<br/>"

    return null
  })

  let reimbursementBody = `<strong><u>Reimbursement Method</u></strong><br><br>Bank: ${state.bank}<br>Account Number: ${state.bankAccountNumber}<br>Account Name: ${state.bankAccountName}<br><br>`

  let receiptBody = `<strong><u>Receipt Details</u></strong><br><br>${state.receipts
    .map(
      (receipt) =>
        `Receipt Number: ${receipt.receiptNumber}<br>Store Name: ${
          receipt.pharmacyName.value
        }<br>Receipt Date: ${formatDate({
          date: receipt.dateOfPurchase,
          format: "ll",
        })}<br>Product(s): ${receipt.items
          .map((item, index, array) =>
            index === array.length - 1
              ? `${item.SKU} - ${item.itemPrice}`
              : `${item.SKU} - ${item.itemPrice},`
          )
          .join("<br>")}<br>`
    )
    .join("<br>")}<br>`

  let viberConsentBody = `<strong><u>Viber Consent</u></strong><br/><br/>I allow MedGrocer to reach out to me for outbound teleconsults and prescription renewal<br/>I allow MedGrocer to enroll me in the Viber community for future communication and announcements.
  `
  template = template + reimbursementBody + receiptBody + viberConsentBody
  template = template + "<br/>"

  return template
}
