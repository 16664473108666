import React from "react"
import classNames from "classnames"
import _ from "lodash"

import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"

import styles from "./utils/summary.module.scss"
import personalInformationFields from "./utils/personalInformationFields"

const PersonalInformationSummary = ({ route, data }) => {
  const bankAccountNumberLabel = data?.bank.includes("UnionBank")
    ? "UnionBank Account Number"
    : "BDO Account Number"
  const bankAccountNameLabel = data?.bank.includes("UnionBank")
    ? "UnionBank Account Name"
    : "BDO Account Name"
  return (
    <Section
      title={`Personal Details`}
      addOns={{
        right: <EditDetailsButton route={route} />,
      }}
    >
      <div className="table-container">
        <table className="table is-fullwidth">
          <tbody>
            {personalInformationFields.map((field) => (
              <tr>
                <td className="has-text-weight-bold">{field.label}</td>
                <td className={classNames(styles["table__data"])}>
                  {field.keys
                    .map((key) =>
                      _.get(data, key) !== "" ? _.get(data, key) : "N/A"
                    )
                    .join(" ")}
                </td>
              </tr>
            ))}
            <tr>
              <td className="has-text-weight-bold">Site Name</td>
              <td className={classNames(styles["table__data"])}>
                {`${data?.siteName?.value}`}
              </td>
            </tr>
            {data?.nps ? (
              <tr>
                <td className="has-text-weight-bold">Customer Feedback</td>
                <td className={classNames(styles["table__data"])}>
                  {`${data?.nps}`}
                </td>
              </tr>
            ) : null}
            <tr>
              <td className="has-text-weight-bold">Bank</td>
              <td className={classNames(styles["table__data"])}>
                {`${data?.bank}`}
              </td>
            </tr>
            {/* <tr></tr> */}
            <tr>
              <td className="has-text-weight-bold">{bankAccountNumberLabel}</td>
              <td className={classNames(styles["table__data"])}>
                {`${data?.bankAccountNumber}`}
              </td>
            </tr>
            <tr>
              <td className="has-text-weight-bold">{bankAccountNameLabel}</td>
              <td className={classNames(styles["table__data"])}>
                {`${data?.bankAccountName}`}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Section>
  )
}

export default PersonalInformationSummary
